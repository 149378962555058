var React = require('react');
var $ = require('jquery');

class SiteResultComponent extends React.Component {

  constructor(props) {
    super(props)
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.getThumbnailPosition = this.getThumbnailPosition.bind(this);
  }

  componentWillReceiveProps() {

    var imageUrl = this.props.site.get("THUMBNAIL_IMAGE");

    if (imageUrl.length) {
      var img = new Image();
      img.src = imageUrl;
    }

  }

  render() {

    var name = this.props.site.get("PLACE_NAME");
    var amenities = this.props.site.get("AMENITIES");
    var address = this.props.site.get("ADDRESS");
    var gisId = this.props.site.get("GIS_ID");
    var activeSiteId = this.props.activeSite ? this.props.activeSite.get("id") : false;
    var isActive = activeSiteId === this.props.site.get("id");

    return (
      <div ref="tile" className={isActive ? 'results-tile is-active' : 'results-tile'} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={this.handleClick}>
        <h3>{name}</h3>
        <p>{amenities}</p>
        <address>{address}</address>
      </div>
    );

  }

  handleMouseEnter() {
    //
    // Show thumbnail
    //
    var thumbnailPosition = this.getThumbnailPosition();

    this.props.mainStateDispatcher({
      action: "setThumbnail",
      thumbnailData: {
        siteModel: this.props.site,
        position: thumbnailPosition
      }
    });

  }

  handleMouseLeave() {
    //
    // Hide thumbnail
    //
    this.props.mainStateDispatcher({
      action: "setThumbnail",
      thumbnailData: {
        siteModel: this.props.site,
        remove: true
      }
    });

  }

  /**
   * When a site is clicked on
   * @return {[type]} [description]
   */
  handleClick() {

    this.props.mainStateDispatcher({
      action: "setActiveSite",
      siteModel: this.props.site,
      showDetails: true,
      showPopup: false
    });

  }

  /**
   * Our thumbnail is positioned absolutely relative to the main container in the app.
   * This method returns some top/left coordinates relative to our tile, so we know where to
   * position the thumbnail on the screen
   */
  getThumbnailPosition() {

    var $tile = $(this.refs.tile);
    var thumbHeight = 112;

    // calculating alignment for thumbnail. Based on result item view height and thumbnail height.
    var left = 60 + $tile.width() + 20;
    var top = $tile.offset().top;

    // top sizing depends on how we are viewing the site: large display, or full bleed
    var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (width > 1024) {
      top -= Number($tile.css("paddingTop").replace("px", ""));
    } else {
      top -= -7;
    }

    top += (($tile.outerHeight() - thumbHeight) / 2);

    return {
      top: top,
      left: left
    };

  }

};

export default SiteResultComponent;
