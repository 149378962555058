var L = require('leaflet');
L.esri = require('esri-leaflet');
var _ = require("underscore");
var Backbone = require('backbone');

/**
 * Holds the sites that we show in the Sidebar module
 */

var TrailModel = Backbone.Model.extend({

  /**
   * Adjusting our site data, so properties are accessible
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  parse: function(data){

    var parsedData = data;

    _.each(data.properties, function(value, key){
      parsedData[key] = value;
    });

    return parsedData;

  }

});

var TrailsCollection = Backbone.Collection.extend({

  // comparator: "PLACE_NAME",

  model: TrailModel,

  initialize: function(){

  },

  fetchTrails: function(){

    var self = this;

    //
    // Init our Sites
    //
    var extentsQuery = L.esri.query({
      url: window.ApiEndpoint + "FeatureServer/4"
    });

    extentsQuery.where("1=1");

    extentsQuery.run(function(error, featureCollection, response){
      self.featureCollection = featureCollection;
      if (featureCollection)
        self.reset(featureCollection.features, {parse: true});
    });

  },

  /**
   * Returning a collection of sites, based on their Site Type (park, openspace, trail)
   * @param  {[type]} type [description]
   * @return {[type]}      [description]
   */
  getByGisId: function(gisId){

    var extent = this.where({"GIS_ID": gisId})[0];

    return typeof extent === "undefined" ? false : extent;

  },

});

module.exports = TrailsCollection;