/*
* This is the main file for Broomfield's Open Space site.
*
* Created by Malaika Penn (Argis Solutions) 2/2021, but the majority of the code was used from the previous application.
*/

import './app.css';
import ReactGA from 'react-ga';
import jQuery from 'jquery'
//import each of the react components
import SplashComponent from "./assets/scripts/components/stage/SplashComponent";
import HeaderComponent from "./assets/scripts/components/header/HeaderComponent";
import SidebarComponent from "./assets/scripts/components/sidebar/SidebarComponent";
import StageComponent from "./assets/scripts/components/stage/StageComponent";

var ReactDOM = require('react-dom');

window.jQuery = jQuery
window.$ = jQuery
window.ApiEndpoint = "https://services1.arcgis.com/vXSRPZbyyOmH9pek/ArcGIS/rest/services/OpenspaceParksTrails/";
window.TileApiEndpoint = "https://tiles.arcgis.com/tiles/vXSRPZbyyOmH9pek/arcgis/rest/services/";

var $ = require('jquery');
var _ = require('underscore');

//This is the basic container for the application, but MainComponent has the meat of the project
function App() {
  return (
    <div>
      <div id='app-container'>
      </div>
    </div>

  );

}

var React = require('react');
var Backbone = require("backbone");
var ReactCSSTransitionGroup = require('react-addons-css-transition-group');

var SitesCollection = require("./assets/scripts/backbone/collections/SitesCollection");
var ExtentsCollection = require("./assets/scripts/backbone/collections/ExtentsCollection");
var TrailsCollection = require("./assets/scripts/backbone/collections/TrailsCollection");
var BoundariesCollection = require("./assets/scripts/backbone/collections/BoundariesCollection");
var TrailheadsCollection = require("./assets/scripts/backbone/collections/TrailheadsCollection");
var NearbysCollection = require("./assets/scripts/backbone/collections/NearbysCollection");
var FeaturesCollection = require("./assets/scripts/backbone/collections/FeaturesCollection");
var PhotosCollection = require("./assets/scripts/backbone/collections/PhotosCollection");
var VideosCollection = require("./assets/scripts/backbone/collections/VideosCollection");


var Stores = {
  sitesCollection: new SitesCollection(),
  extentsCollection: new ExtentsCollection(),
  trailsCollection: new TrailsCollection(),
  boundariesCollection: new BoundariesCollection(),
  trailheadsCollection: new TrailheadsCollection(),
  nearbysCollection: new NearbysCollection(),
  featuresCollection: new FeaturesCollection(),
  photosCollection: new PhotosCollection(),
  videosCollection: new VideosCollection()
};

Stores.sitesCollection.fetchSites();
Stores.extentsCollection.fetchExtents();
Stores.trailsCollection.fetchTrails();
Stores.boundariesCollection.fetchBoundaries();
Stores.trailheadsCollection.fetchTrailheads();
Stores.nearbysCollection.fetchNearbys();
Stores.featuresCollection.fetchFeatures();
Stores.photosCollection.fetchPhotos();
Stores.videosCollection.fetchVideos();

ReactGA.initialize('UA-68109808-2'); //Unique Google Analytics tracking number
ReactGA.pageview(window.location.pathname + window.location.search);

//Getting the segment from the URL. Used below to figure out which view and site we're looking at
var getSegment = function (segmentNumber) {

  var newURL = window.location.protocol + "://" + window.location.host + "/" + window.location.pathname;
  var pathArray = window.location.pathname.split('/');

  if (typeof pathArray[segmentNumber] === "undefined") {
    return false;
  }

  return pathArray[segmentNumber];

}

//The main component
class MainComponent extends React.Component {

  constructor(props) {
    super(props)
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    this.state = {
      width: w,
      height: h
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.mainStateDispatcher = this.mainStateDispatcher.bind(this);

    this.state = {
      view: false,
      thumbnailData: new Backbone.Collection(),
      searchTerm: false,
      areSearching: false,
      activeSite: false,
      showDetails: false,
      showPopup: false,
      featureId: false,
      showFeedbackModal: false
    };

    var self = this;

    setTimeout(function () {

      //
      // Set the view to all, and then update it if segments appear below
      //
      var state = {
        view: "all"
      };

      //
      // Setting the site type
      //
      var segmentOne = getSegment(1);
      if (segmentOne) {

        var segment = segmentOne;

        if (segment === "parks") {
          segment = "park";
        }

        if (segment === "trails") {
          segment = "trail";
        }

        if (segment === "openspaces") {
          segment = "openspace";
        }

        state.view = segment;

      }

      //
      // Setting our individual place if it exists
      //
      var segmentTwo = getSegment(2);
      if (segmentTwo) {

        var slug = segmentTwo;
        var activeSite = Stores.sitesCollection.where({ slug: slug })[0];

        if (typeof activeSite !== "undefined") {
          state.activeSite = activeSite;
          state.showDetails = true;
        }

      }

      self.setState(state);

    }, 1000);

  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  //A property on our main wrapper, which sets app-wide states, which cascade down towards child components
  mainStateDispatcher(options) {
    switch (options.action) {
      //
      // This puts our thumbnail into the DOM
      //
      case "setThumbnail":
        this.setThumbnailData(options.thumbnailData);
        break;

      //
      // This sets our category view
      //
      case "setView":
        this.setView(options.viewName);
        this.handleUrlUpdate(options.viewName);
        break;

      //
      // Does what you think
      //
      case "setSearchTerm":
        this.setSearchTerm(options.searchTerm);
        break;

      //
      // Showing a Feature Popup (not a site, but a feature within a site)
      //
      case "showFeaturePopup":
        this.setState({
          showDetails: false,
          featureId: options.featureId
        });
        break;

      case "showFeedbackModal":
        this.setState({
          showDetails: false
        })

      //
      // This is where things get tricky.
      //
      case "setActiveSite":

        var defaultOptions = {

          // should we be showing the popup?
          showPopup: false,

          // should we show the details view?
          showDetails: false,

          // the site model we're setting to active
          siteModel: false

        }


        options = _.extend(defaultOptions, options);

        var newState = {};

        if (options.siteModel.getPermalink() !== window.location.pathname) {
          newState.view = options.siteModel.get("SITE_TYPE").toLowerCase().replace(" ", "");
        }

        newState.showPopup = options.showPopup;
        newState.showDetails = options.showDetails;
        newState.activeSite = options.siteModel;
        newState.searchTerm = false;
        newState.showFeedbackModal = options.showFeedbackModal;

        this.setState(newState);
        this.handleUrlUpdate(options.siteModel);

        break;

      case "setShowDetails":
        this.setState({
          showDetails: options.showDetails
        });
        break;

      case "closeSite":
        this.setState({
          showPopup: false,
          showDetails: false
        });
        this.handleUrlUpdate(this.state.view);
        break;

      case "setAreSearching":
        this.setState({
          areSearching: options.areSearching
        });
        break;

    }

  }

  //A method coming from our mainStateDispatcher property, setting the searchTerm
  setSearchTerm(searchTerm) {

    this.setState({
      searchTerm: searchTerm
    });

  }

  // A method coming from our mainStateDispatcher property, setting the view (park, trail, openspace) for the app
  //@param {[type]} viewName [description]
  setView(viewName) {

    var newState = {
      view: viewName,
      activeSite: false,
      showDetails: false
    }

    if (viewName !== this.state.view) {
      newState.searchTerm = false;
    }

    this.setState(newState);

  }

  //A method coming from our mainStateDispatcher property, setting the thumnbail, after a site result tile has been hovered over
  //@param {[type]} thumbnailData [description]
  setThumbnailData(thumbnailData) {

    // make a copy of our site model, so we don't mess with anything in other components
    var newSiteModel = thumbnailData.siteModel.clone();

    // if we have position data (where it should be placed on-hover) lets add it into the model itself
    if (typeof thumbnailData.position !== "undefined" && thumbnailData.position) {
      newSiteModel.set("position", thumbnailData.position);
    }

    // I don't want to update the state object directly, so we'll update it as a local variable
    var thumbnailDataCollection = this.state.thumbnailData;

    // Are we adding or removing the thumbnail from the viewport?
    if (typeof thumbnailData.remove !== "undefined" && thumbnailData.remove) {
      thumbnailDataCollection.remove(newSiteModel);
    } else {
      thumbnailDataCollection.add(newSiteModel, { merge: true });
    }

    // Updating the state itself.
    this.setState({
      thumbnailData: thumbnailDataCollection
    });

  }

  //This method builds the thumbnail we show when someone hovers over a site result.
  //It does quite a bit of fancy stuff because we need to position it pseudo-relatively, but in-fact asbsolutely.
  // @return {[type]} [description]
  getThumbnail() {
    var thumbnails = [];

    // We want to set the wrapper relative to where the sidebar ends
    var wrapperStyles = {
      left: $("[data-comp-sidebar-results]").width(),
    };
    if (this.state.thumbnailData && this.state.thumbnailData.length) {

      // Looping over each of our thumbnails
      this.state.thumbnailData.each(function (siteModel) {

        //
        // All the dynamic data for our thumbnail.
        // The thumbnailStyles help position it inside the viewport, and imageStyles are for the display
        // of the image inside the thumbnail
        //
        var id = siteModel.get("id");
        var thumbnailStyles = { top: siteModel.get("position").top, left: 0 }
        var imageUrl = siteModel.get("THUMBNAIL_IMAGE");
        var imageStyles = { backgroundImage: "url(" + imageUrl + ")" };

        // Adding the thumbnail
        thumbnails.push((
          <div key={id} className="thumbnail" style={thumbnailStyles}>
            <div className="bg-container" style={imageStyles}>
              <span></span>
            </div>
          </div>
        ));

      });

    }

    // Throwing this in an animated transition group for fancy transitions.
    return (
      <ReactCSSTransitionGroup
        transitionName="thumbnail"
        transitionEnterTimeout={0}
        transitionLeaveTimeout={0}
        component="div"
        className="thumbnail-wrapper"
        style={wrapperStyles}>

        {thumbnails}

      </ReactCSSTransitionGroup>
    )
  }

  render() {
    var thumbnail = this.getThumbnail();
    var searchTerm = this.state.searchTerm;
    var areSearching = this.state.areSearching;
    var activeSite = this.state.activeSite;
    var showDetails = this.state.showDetails;
    var showPopup = this.state.showPopup;
    var featureId = this.state.featureId;
    var showFeedbackModal = this.state.showFeedbackModal;

    return (
      <main className="row full-width bg-white">
        <HeaderComponent
          view={this.state.view}
          mainStateDispatcher={this.mainStateDispatcher.bind(this)}
          activeSite={activeSite}
          showFeedbackModal={showFeedbackModal}
        />
        <SplashComponent />

        <div data-comp-content className="row collapse">

          <SidebarComponent
            mainStateDispatcher={this.mainStateDispatcher.bind(this)}
            view={this.state.view}
            stores={Stores}
            searchTerm={searchTerm}
            areSearching={areSearching}
            activeSite={activeSite}
            width={this.state.width}
            height={this.state.height}
          />

          <StageComponent
            mainStateDispatcher={this.mainStateDispatcher}
            view={this.state.view}
            stores={Stores}
            searchTerm={searchTerm}
            areSearching={areSearching}
            activeSite={activeSite}
            showDetails={showDetails}
            showPopup={showPopup}
            showFeedbackModal={showFeedbackModal}
            featureId={featureId}
          />

        </div>

        {thumbnail}

      </main>
    );
  }

  // Updating URL to match format
  handleUrlUpdate(param) {
    var url;
    if (typeof param === "string") {

      if (param !== "all") {
        url = "/" + param + "s";
        window.history.pushState({}, "", url);
      } else {
        window.history.pushState({}, "", "/");
      }
    } else {
      var siteModel = param;
      url = siteModel.getPermalink();
      window.history.pushState({}, "", url);

    }
  }
};

//add the main component to the starting page, this starts the whole process
$(window).ready(function () {
  ReactDOM.render(
    <MainComponent />,
    document.getElementById('app-container')
  );

});

export default App;
