import * as L from 'leaflet';
import * as L1 from 'leaflet.markercluster'
var React = require('react');
var ReactDOMServer = require('react-dom/server');
var $ = require('jquery');
var _ = require('underscore');
require('leaflet-draw');
require('leaflet');
require("../../vendor/leaflet.measureddistance.js");
require("leaflet.locatecontrol");
require("leaflet-html-legend");
require("leaflet-easybutton");

/**
 * This is a MASSIVE component that handles the rendering and drawing of the map. It's unique in that
 * it uses jQuery and some native Leaftlet/ESRI DOM stuff, in additional to all the React work.
 *
 * I tried to break the component up into different section with different responsibilities, for the sake of organization.
 * These responsibilities could potentially be refactored into their own classes at some point.
 */
class MapComponent extends React.Component {

  constructor(props) {
    super(props)

    /**
   * The layer holding all our markers
   */
    this.markerLayer = new L1.MarkerClusterGroup({
      iconCreateFunction: function (cluster) {
        return L.divIcon({ html: '<div class="cluster-icon"><span>' + cluster.getChildCount() + '</span></div>' });
      }
    })

    this.state = {
      gettingDirections: false
    };
  }

  /**
   * Called the first time the component mounts
   * @return {[type]} [description]
   */
  componentDidMount() {

    // Kicking off the init'ing of all our maps
    this.initBaseMaps();
    this.initBackgroundOverlays();
    this.addMarkers();
    this.markerLayer.addTo(this.map);

    this.initLocateControl();
    // Init'ing drawing functionality
    this.initDrawing();
    // Init'ing legend functionality
    this.initLegendControl();

    // Binding events that rely on jquery not react
    this.bindNonReactableEvents();

  }

  /**
   * When we need to make updates to the DOM
   * @return {[type]} [description]
   */
  componentDidUpdate(previousProps, previousState) {

    if (previousProps.filteredSitesCollection.uniqueHash !== this.props.filteredSitesCollection.uniqueHash || (previousProps.activeSite !== this.props.activeSite)) {

      // when the component updates, remove all the markers
      this.markerLayer.clearLayers();


      // add in markers
      this.addMarkers();

    }


    // If we have an active Site
    if ((previousProps.activeSite !== this.props.activeSite) && this.props.activeSite !== false) {

      this.zoomOnSite(this.props.activeSite);


      // We're closing a site and going to wide view
    } else if ((previousProps.activeSite !== this.props.activeSite) && this.props.activeSite === false) {


      this.zoomToStartingPosition();

    } else if (previousProps.view !== this.props.view) {


      this.zoomToStartingPosition();

    } else if (this.props.featureId > 0 && this.props.featureId !== previousProps.featureId) {

      this.showFeaturePopup(this.props.featureId);

    }

  }

  /**
   * Because some of our events can't be accessed with JSX elements
   * we needo to bind using jquery
   * @return {[type]} [description]
   */
  bindNonReactableEvents() {
    var self = this;

    $(this.refs.mapContainer).on("click", ".close", function (e) {
      e.preventDefault();
      self.map.closePopup();
      self.handleRemoveDirections(this)
    });

    $(this.refs.mapContainer).on("click", ".see-details", function (e) {
      e.preventDefault();
      self.handleSeeDetails(this);
      self.handleRemoveDirections(this)
    });

    $(this.refs.mapContainer).on("click", ".zoom-to-place", function (e) {
      e.preventDefault();
      self.handleZoomOnSite(this);
      self.handleRemoveDirections(this)
    });

    $(this.refs.mapContainer).on("click", ".get-directions", function (e) {
      e.preventDefault();
      self.handleGetDirections(this);
      self.setState({
        gettingDirections: true
      });
    });

    this.map.on('popupopen', function (e) {
      var px = self.map.project(e.popup._latlng); // find the pixel location on the map where the popup anchor is
      px.y -= e.popup._container.clientHeight / 2 // find the height of the popup container, divide by 2, subtract from the Y axis of marker location
      self.map.panTo(self.map.unproject(px), { animate: true }); // pan to new center
      self.handleRemoveDirections(this)
    });

  }
  //
  // Map Methods
  //

  /**
   * Initing our basemaps
   * @return {[type]} [description]
   */
  initBaseMaps() {

    // ArcGIS Online Basemaps - Streets, Topographic, Gray, GrayLabels, Oceans, NationalGeographic, Imagery, ImageryLabels

    var gray = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}' + (L.Browser.retina ? '@2x.png' : '.png'), {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 20,
      minZoom: 0
    });
    var gray_2 = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}' + (L.Browser.retina ? '@2x.png' : '.png'), {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 20,
      minZoom: 0
    });

    var imagery = L.esri.basemapLayer("Imagery", {
      zIndex: 2
    });

    var cartodbUrl = 'https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png';

    var labels_layer = L.tileLayer(cartodbUrl, {
      id: 'cartodb_labels',
      zIndex: 10
    })

    var baseMaps = {
      "Grayscale Landcover": gray,
      "Grayscale": gray_2,
      "Satellite": imagery,
    };

    this.map = L.map(this.refs.mapContainer, {
      center: [39.920541, -105.06665],
      zoom: 1,
      layers: [labels_layer, gray]
    });

    L.control.layers(baseMaps).addTo(this.map);

  }

  /**
   * Initing overlays and some helpers layers to add detail to the site
   * @return {[type]} [description]
   */
  initBackgroundOverlays() {
    var self = this;

    // L.esri.basemapLayer('ImageryTransportation').addTo(this.map);

    this.map.createPane('featuresLayer');
    this.map.getPane('featuresLayer').style.zIndex = 15;
    this.map.createPane('extentLayer');
    this.map.getPane('extentLayer').style.zIndex = 5;
    this.map.getPane('extentLayer').style.pointerEvents = 'none';
    this.map.createPane('TopOverlay');
    this.map.getPane('TopOverlay').style.zIndex = 4;
    this.map.getPane('TopOverlay').style.pointerEvents = 'none';
    this.map.createPane('trailsLayer');
    this.map.getPane('trailsLayer').style.zIndex = 3;
    this.map.getPane('trailsLayer').style.pointerEvents = 'none';

    //
    // This is the background of the map, currently with a green background
    //
    this.bottomLayer = new L.esri.tiledMapLayer({
      url: window.TileApiEndpoint + "BottomOverlayLayers/MapServer",
      opacity: 0.6,
      minZoom: 12,
      maxZoom: 20,
      zIndex: 2

    }).addTo(this.map);

    //
    // Trails
    //
    this.trailsLayerExternal = new L.esri.featureLayer({
      url: window.ApiEndpoint + "FeatureServer/5",
      opacity: 0.5,
      style: function (feature) {

        // Types: Attached Sidewalk, Cross-Walk, Detached Sidewalk, Driveway, Multi-Use Path, Soft Surface Trail, On-street Bike Lane
        var type = feature.properties.SUBTYPE_TEXT;
        var styles, dashArray;

        styles = {
          weight: 2,
          // color: "#a77d47",
          color: "#A67C65",
          opacity: .5
        };

        if (type === "Attached Sidewalk" || type === "Detached Sidewalk" || type === "Multi-Use Path" || type === "On-street Bike Lane") {
          dashArray = false;
        } else if (type === "Soft Surface Trail") {
          dashArray = "5,5";
        } else {
          // Cross-Walk, Driveway
          dashArray = "5,5,1,5";
        }

        if (dashArray) {
          styles.dashArray = dashArray;
        }
        return styles;
      }


    }).addTo(this.map);

    this.trailsLayer = new L.esri.featureLayer({

      url: window.ApiEndpoint + "FeatureServer/4",
      opacity: 1,
      pane: 'trailsLayer',
      style: function (feature) {

        // Types: Attached Sidewalk, Cross-Walk, Detached Sidewalk, Driveway, Multi-Use Path, Soft Surface Trail, On-street Bike Lane
        var type = feature.properties.TRAIL_TYPE;
        var styles, dashArray;


        styles = {
          weight: 2,
          // color: "#a77d47",
          color: "#A67C65",
          opacity: 1
        };

        if (type === "Attached Sidewalk" || type === "Detached Sidewalk" || type === "Multi-Use Path" || type === "On-street Bike Lane") {
          dashArray = false;
        } else if (type === "Soft Surface Trail") {
          dashArray = "5,5";
        } else {
          // Cross-Walk, Driveway
          dashArray = "5,5,1,5";
        }

        if (dashArray) {
          styles.dashArray = dashArray;
        }

        return styles;



      }


    }).addTo(this.map);


    //filters out on-street bike lanes
    this.trailsLayer.setWhere("TRAIL_TYPE <> 'On-street Bike Lane'")

    this.topLayer = new L.esri.tiledMapLayer({
      url: window.TileApiEndpoint + "TopOverlayLayers/MapServer",
      opacity: 1,
      minZoom: 12,
      maxZoom: 20,
      pane: 'TopOverlay'
    }).addTo(this.map);


    this.extentLayer = new L.esri.featureLayer({

      url: window.ApiEndpoint + "FeatureServer/3",
      pane: 'extentLayer',
      style: function (feature) {
        if (feature.properties.SITE_TYPE === "Park") {
          return { color: "#556221", weight: 2 };
        } else if (feature.properties.SITE_TYPE === "Open Space") {
          return { color: "#FFC627", weight: 2 };
        } else {
          return { color: "#DD5F13", weight: 2 };
        }
      }

    }).addTo(this.map);

    var featuresWithNoPhoto = [];

    //
    // Feature icons
    //

    this.featuresLayer = new L.esri.featureLayer({

      url: window.ApiEndpoint + "FeatureServer/1",
      opacity: 1,
      // zIndex: 21,
      where: "1=0",
      pane: 'featuresLayer',

      // for each feature we want to create and assign the icon, and also create a popup with
      // the feature's name and associated image
      onEachFeature: function (feature, layer) {

        var gisId = feature.properties.GIS_ID;
        var relatedSiteId = feature.properties.F_SITE_GIS_ID;
        var featurePhotoModel = self.props.stores.photosCollection.getFeaturedImage(feature.properties.F_PHOTO_GIS_ID);

        var iconUrl = self.getFeatureIconUrl(feature);
        var icon = L.icon({
          iconUrl: iconUrl,
          iconSize: [27, 25],
          iconAnchor: [13, 37],
          popupAnchor: [3, -40],
        });

        var relatedSites = self.props.stores.sitesCollection.getSitesByIds([relatedSiteId]);
        var relatedSiteId = "";

        if (relatedSites.length) {
          var relatedSiteId = relatedSites.first().get("GIS_ID");
        }
        var getPhoto = function (featurePhotoModel) {

          return !featurePhotoModel ? null : (
            <img src={featurePhotoModel.get("SOURCE_URL")} />
          );

        }
        var photo = getPhoto(featurePhotoModel);

        var popupDom = (
          <div className='popup image-popup'>

            <h4>Feature</h4>
            <h5>{feature.properties.SPECIFIC_NAME}</h5>

            {photo}

            <ul>
              <li><a className="see-details" data-gis-id={relatedSiteId}>VIEW SITE</a></li>
            </ul>

            <a className='close ss-delete ss-pika ss-icon icon' ></a>

          </div>
        );

        var popupHtml = ReactDOMServer.renderToStaticMarkup(popupDom);
        var popup = L.popup().setContent(popupHtml);

        //
        // Hiding Features if they don't have a photo
        //

        layer.setIcon(icon);
        layer.bindPopup(popup);
      }

    }).addTo(this.map);
  }

  /**
     * Returning the correct URL depending on the feature itself
     * @param  {object} feature the feature object
     * @return {string}
     */
  getFeatureIconUrl(feature) {

    var iconUrl = feature.properties.AMENITY_TYPE;
    iconUrl = "/assets/img/feature-icons/" + iconUrl + ".png";
    return iconUrl;

  }

  /**
   * Adding markers on to the map
   */
  addMarkers() {
    var self = this;
    var latlng;
    var site_type_set = new Set();
    this.map.createPane('markers');
    this.map.getPane('markers').style.zIndex = 15;

    this.props.filteredSitesCollection.each(function (siteModel) {

      latlng = new L.LatLng(siteModel.get("geometry").coordinates[1], siteModel.get("geometry").coordinates[0]);

      var marker = L.marker(latlng, {
        pane: 'markers',
        icon: L.icon({
          iconUrl: self.getMarkerIcon(siteModel),
          iconRetinaUrl: self.getMarkerIcon(siteModel),
          iconSize: [30, 41],
          iconAnchor: [13, 37],
          popupAnchor: [3, -40],
          shadowUrl: "/assets/img/marker-shadow.png",
          shadowRetinaUrl: "/assets/img/marker-shadow.png"
        })
      });

      var popup = self.buildPopup(siteModel);

      marker.addTo(self.markerLayer).bindPopup(popup);
      siteModel.set("marker", marker);
      siteModel.set("popup", popup);

      site_type_set.add(siteModel.get("SITE_TYPE"))
    });

    if (this.props.filteredSitesCollection.length > +1) {
      this.extentLayer.setWhere("SITE_TYPE in (" + Array.from(site_type_set).map(x => "'" + x + "'").toString() + ")");
    }
    else {
      this.extentLayer.setWhere("1=0");
    }
  }

  /**
   * Returning our marker's icon, based on the site type
   */
  getMarkerIcon(siteModel) {

    var activeSiteId = false;

    if (this.props.activeSite) {
      activeSiteId = this.props.activeSite.get("id");
    }

    if (siteModel.get("SITE_TYPE") === "Open Space") {
      if (siteModel.get("id") === activeSiteId) {
        return '/assets/img/pin-yellow-active.png';
      } else {
        return '/assets/img/pin-yellow.png';
      }
    } else if (siteModel.get("SITE_TYPE") === "Trail") {
      if (siteModel.get("id") === activeSiteId) {
        return '/assets/img/pin-brown-active.png';
      } else {
        return '/assets/img/pin-brown.png';
      }
    } else {
      if (siteModel.get("id") === activeSiteId) {
        return '/assets/img/pin-green-active.png';
      } else {
        return '/assets/img/pin-green.png';
      }
    }

  }

  /**
   * Building the popup for each marker
   */
  buildPopup(siteModel) {

    var name = siteModel.get("PLACE_NAME");
    var gisId = siteModel.get("GIS_ID");
    var type = siteModel.get("SITE_TYPE");
    var formattedType = type.toLowerCase().replace(" ", "");

    var popup = (
      <div className={"popup " + formattedType}>

        <h4>{type}</h4>

        <h5>
          {name}
        </h5>

        <img src={siteModel.get("THUMBNAIL_IMAGE")} />

        <ul>
          <li><a className="see-details" data-gis-id={gisId}>SEE DETAILS</a></li>
          <li><a className="get-directions" data-gis-id={gisId}>GET DIRECTIONS <img src="/assets/img/loader.svg" className="getting-directions-loader" /></a></li>
          <li><a className="zoom-to-place" data-gis-id={gisId}>ZOOM</a></li>
        </ul>

        <a className="close ss-delete ss-pika ss-icon ss-pika ss-delete" onClick={this.handleClose}></a>
      </div>
    );

    var popupHtml = ReactDOMServer.renderToStaticMarkup(popup);

    var latlng = new L.LatLng(siteModel.get("geometry").coordinates[1], siteModel.get("geometry").coordinates[0]);

    return new L.Popup({}).setLatLng(latlng).setContent(popupHtml);

  }

  //
  // Geolocation control functionality
  //

  initLocateControl() {

    var lc = L.control.locate({
      position: 'topright',
      icon: 'fa fa-location-arrow',
      showPopup: false,
      locateOptions: {
        maxZoom: 17
      }


    }).addTo(this.map);
  }

  //
  // Legend functionality
  //
  initLegendControl() {

    //legend
    var legend = L.control({ position: 'topright' });
    legend.onAdd = function (map) {

      var div = L.DomUtil.create('div', 'leaflet-html-legend')

      div.innerHTML += '<svg width="180" height="120">\
        "<line x1="10" y1="20" x2="40" y2="20" stroke="#A67C65" class="dashed-path" />\
        "<text x="40" y="20" transform="translate(8,4)">Soft Surface Trail</text>\
        "<line x1="10" y1="40" x2="40" y2="40" stroke="#A67C65" stroke-dasharray="4 1" class="solid-path" />\
        "<text x="40" y="40"transform="translate(8,4)">Hard Surface Trail</text>\
        "<rect x= "15" y="53" width="15" height="15" style="fill:#94ae88" />\
        "<text x="40" y="60"transform="translate(8,4)">Open Space</text>\
        "<rect x= "15" y="73" width="15" height="15" style="fill:#c1e0be" />\
        "<text x="40" y="80"transform="translate(8,4)">Park</text>\
        "<rect x= "15" y="93" width="15" height="15" style="fill:#e4e3c5" />\
        "<text x="40" y="100"transform="translate(8,4)">Other Open Lands</text>\
     "</svg>';


      return div;
    };


    this.legendtoggle = L.easyButton({
      states: [{
        stateName: 'add-legend',
        icon: 'fa fa-map-signs',
        title: 'add legend',
        onClick: function (control, map) {
          legend.addTo(map);
          control.state('remove-legend');
        }
      }, {
        icon: 'fa-undo',
        stateName: 'remove-legend',
        onClick: function (control, map) {
          $(".leaflet-html-legend").hide();
          control.state('add-legend');
        },
        title: 'remove legend'
      }]
    });

    this.legendtoggle.addTo(this.map);

  }


  //
  // Drawing functionality
  //

  /**
   * Initing our Drawing functionality
   */
  initDrawing() {

    // Creating our drawing layer and adding it to the map
    this.drawingLayer = new L.LayerGroup();
    this.map.addLayer(this.drawingLayer);

    // Other init'ing we need to do
    this.initDrawingControls();
    this.initDrawingEvents();

  }

  /**
   * Loading the UI / Buttons facilitating our buttons
   */
  initDrawingControls() {

    var self = this;

    // Set the button title text
    L.drawLocal.draw.toolbar.buttons.polyline = '';
    L.drawLocal.edit.toolbar.buttons.edit = '';
    L.drawLocal.edit.toolbar.buttons.remove = '';

    // Initialise the FeatureGroup to store editable layers
    this.drawnItems = new L.FeatureGroup([], {
      zIndex: 100
    }).on("click", function () {

      // console.log("clicked");

    });

    this.map.addLayer(this.drawnItems);

    // Initialise the draw control and pass it the FeatureGroup of editable layers
    this.drawControl = new L.Control.Draw({
      draw: {
        circlemarker: false,
        circle: false,
        marker: false,
        rectangle: false,
        polygon: false,
        polyline: {
          guideLayers: [this.trailsLayer],
          metric: false,
          shapeOptions: {
            color: '#5dace9'
          },
          zIndexOffset: 2000000
        }
      },
      edit: {
        featureGroup: this.drawnItems,
        edit: false
      }
    });

    this.map.addControl(this.drawControl);

  }

  /**
   * Init'ing some events that assist with drawing
   */

  initDrawingEvents() {
    var self = this;

    // after the polyline has been created, add it to the map
    this.map.on('draw:created', function (e) {
      var type = e.layerType,
        layer = e.layer;
      self.drawnItems.addLayer(e.layer);
      self.renderDrawingMarker(e.layer);

    });

    // removing markers associated to a layer
    this.map.on('draw:deleted', function (e) {

      _.each(e.layers._layers, function (polyline) {
        self.map.removeLayer(polyline);
      });

    });

    this.map.on('draw:editstart', function (e) {
    })
  }

  /**
   * Rendering a marker that's shown when a user completes a drawing
   */
  renderDrawingMarker(polyline) {

    var self = this;

    var distance = polyline.measuredDistance({
      metric: false
    });

    var latLngs = polyline.getLatLngs();
    var lastLatLng = latLngs[latLngs.length - 1];

    var latlng = new L.LatLng(lastLatLng.lat, lastLatLng.lng);

    self.map.createPane('markersTrailHead');
    self.map.getPane('markersTrailHead').style.zIndex = 20;

    var marker = L.marker(latlng, {
      pane: 'markersTrailHead',
      icon: L.icon({
        iconUrl: "/assets/img/pin-blue.png",
        iconRetinaUrl: "/assets/img/pin-blue.png",
        iconSize: [26, 37],
        iconAnchor: [13, 37],
        popupAnchor: [-10, -25],
        shadowUrl: "/assets/img/marker-shadow.png",
        shadowRetinaUrl: "/assets/img/marker-shadow.png"
      })
    });

    var popup = new L.Popup({}).setLatLng(latlng).setContent("<div class='popup'><h5>Distance: " + distance + "</h5></div>");
    polyline.addTo(this.drawingLayer).bindPopup(popup);
    this.map.openPopup(popup);

  }
  //
  // Rednering and Interactions
  //

  /**
   * Rendering our component
   */
  render() {
    var gettingDirections = false;
    if (this.state && this.state.gettingDirections) {
      gettingDirections = this.state.gettingDirections;
    }

    return (
      <div data-comp-map className={"map-region"} ref="mapContainer">
        <div ref="mapContainer" className={"map-container " + (gettingDirections ? 'getting-directions' : '')}>
        </div>
      </div>
    );

  }

  /**
   * Handler when the user wants to see the details of a site
   * @param  {[type]} el [description]
   * @return {[type]}    [description]
   */
  handleSeeDetails(el) {

    var gisId = $(el).data("gis-id");

    var siteModel = this.props.stores.sitesCollection.where({
      GIS_ID: gisId
    })[0];

    this.props.mainStateDispatcher({
      action: "setActiveSite",
      siteModel: siteModel,
      showDetails: true
    });

  }

  /**
   * Hanlder when someone wants to zoom in the map onto a site
   * @param  {[type]} el [description]
   * @return {[type]}    [description]
   */
  handleZoomOnSite(el) {

    var gisId = $(el).data("gis-id");

    var siteModel = this.props.stores.sitesCollection.where({
      GIS_ID: gisId
    })[0];

    this.props.mainStateDispatcher({
      action: "setActiveSite",
      siteModel: siteModel
    });

  }

  /**
   * The handler after a user has click a get directions popup
   * @param  {[type]} el [description]
   * @return {[type]}    [description]
   */
  handleGetDirections(el) {

    var gisId = $(el).data("gis-id");

    var siteModel = this.props.stores.sitesCollection.where({
      GIS_ID: gisId
    })[0];

    siteModel.getDirections();
    $(".getting-directions-loader").show();

  }

  /**
   * Zoom map to starting position on page load
   */
  zoomToStartingPosition() {

    this._removeDetailHighlights();

    var latlng = L.latLng(39.920541, -105.06665);

    this.map.setView(latlng, 13);

  }

  /**
   * When we want to zoom in on an individual site
   * @param  {[type]} activeSite [description]
   * @return {[type]}            [description]
   */
  zoomOnSite(activeSite) {
    this._removeDetailHighlights();

    this.map.createPane('markersTrailHead');
    this.map.getPane('markersTrailHead').style.zIndex = 20;
    this.map.getPane('markersTrailHead').style.pointerEvents = 'none';
    this.map.createPane('trailheadsHighlight');
    this.map.getPane('trailheadsHighlight').style.zIndex = 9;
    this.map.createPane('extentLayerHighlight');
    this.map.getPane('extentLayerHighlight').style.zIndex = 7;
    this.map.createPane('trailLayerHighlight');
    this.map.getPane('trailLayerHighlight').style.zIndex = 7;

    //
    // Trail
    //
    var features = [];
    if (this.props.stores && this.props.stores.trailsCollection && this.props.stores.trailsCollection.featureCollection && this.props.stores.trailsCollection.featureCollection.features) {
      features = this.props.stores.trailsCollection.featureCollection.features;
    }
    this.trailLayerDetailHighlight = L.geoJson(features, {
      pane: 'trailLayerHighlight',
      style: {
        color: "#002F87"
      },
      filter: function (feature, layer) {
        return feature.properties.GIS_ID === activeSite.get("GIS_ID");
      }
    }).addTo(this.map);

    //
    // Bondary / Polygon
    //

    this.extentLayerDetailHighlight = L.geoJson(this.props.stores.extentsCollection.featureCollection, {
      pane: 'extentLayerHighlight',
      zIndex: 1000,
      clickable: false,
      style: {
        color: "#002F87"
      },
      filter: function (feature, layer) {
        return feature.properties.GIS_ID === activeSite.get("GIS_ID");
      }
    }).addTo(this.map);

    //
    // Trailhead layer
    //

    this.trailheadsLayerDetailHighlight = L.geoJson(this.props.stores.trailheadsCollection.featureCollection, {
      pane: 'trailheadsHighlight',
      filter: function (feature, layer) {
        return feature.properties.F_SITE_GIS_ID === activeSite.get("GIS_ID");
      },
      pointToLayer: function (feature, latlng) {

        return L.marker([feature.geometry.coordinates[1], feature.geometry.coordinates[0]], {
          pane: 'markersTrailHead',
          icon: new L.icon({
            iconUrl: "/assets/img/pin-red-trailhead.png",
            iconRetinaUrl: "/assets/img/pin-red-trailhead.png",
            iconSize: [26, 26]
          })
        });

      }
    }).addTo(this.map);

    var siteType = activeSite.get("SITE_TYPE").toLowerCase();

    if (siteType == "trail") {
      var minLong = activeSite.attributes.XMin;
      var minLat = activeSite.attributes.YMin;
      var maxLong = activeSite.attributes.XMax;
      var maxLat = activeSite.attributes.YMax;
      var bounds = [[minLat, minLong], [maxLat, maxLong]];

      this.map.fitBounds(bounds);

    } else {
      var bounds = this.extentLayerDetailHighlight.getBounds();

      this.map.fitBounds(bounds);
    }
    this.featuresLayer.setWhere("F_SITE_GIS_ID = " + activeSite.get("GIS_ID"));
  }

  /**
   * When we want to remove the highlights we display on a map shown on the detail view.
   * @return {[type]} [description]
   */
  _removeDetailHighlights() {

    var self = this;

    if (typeof this.trailLayerDetailHighlight !== "undefined") {
      self.map.removeLayer(this.trailLayerDetailHighlight);

    }

    if (typeof this.extentLayerDetailHighlight !== "undefined") {
      self.map.removeLayer(this.extentLayerDetailHighlight);
    }

    if (typeof this.trailheadsLayerDetailHighlight !== "undefined") {
      self.map.removeLayer(this.trailheadsLayerDetailHighlight);
    }

    self.featuresLayer.setWhere("1=0");
    self.extentLayer.setWhere("1=0");
  }

  /**
   * Showing our feature layer, from a Nearby click in the details view
   */
  showFeaturePopup(featureId) {
    var featureLayer = this.featuresLayer.getFeature(featureId);
    featureLayer.openPopup();
  }

  handleRemoveDirections() {

    this.setState({
      gettingDirections: false
    });

    $(".getting-directions-loader").hide();

  }
};

export default MapComponent;
