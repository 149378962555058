
var React = require('react');

class DropdownInputComponent extends React.Component {
  render() {

    return (
      <div id='row-2' className="row">
        <div className="columns small-12 filter-input-container select">
          <select className="select-input span light-green">
            <option value="">Sort Results</option>
            <option></option>
          </select>
        </div>
      </div>
    );

  }

};

export default DropdownInputComponent;