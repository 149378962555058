import ImageGallery from 'react-image-gallery';
import Foundation from 'foundation-sites';
var React = require('react');
var _ = require("underscore");
var $ = require('jquery');

class DetailsComponent extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      gettingDirections: false
    };

    this.getVideosModal = this.getVideosModal.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleShowMap = this.handleShowMap.bind(this);
    this.handleGetDirections = this.handleGetDirections.bind(this);
    this.handleRemoveDirections = this.handleRemoveDirections.bind(this);
    this.handleShowVideo = this.handleShowVideo.bind(this);
    this.handleCloseVideo = this.handleCloseVideo.bind(this);
    this.getContactLinks = this.getContactLinks.bind(this);
    this.getVideoLinks = this.getVideoLinks.bind(this);
    this.getGalleryPhotos = this.getGalleryPhotos.bind(this);
    this.getGalleryCaptions = this.getGalleryCaptions.bind(this);
    this.getNearbyContent = this.getNearbyContent.bind(this);
    this.getFeaturesContent = this.getFeaturesContent.bind(this);
  }

  handleOnClose() {
    this.handleRemoveDirections();
    this.props.mainStateDispatcher({
      action: "closeSite"
    });

  }

  handleShowMap() {

    console.log("show map");

    this.props.mainStateDispatcher({
      action: "setShowDetails",
      showDetails: false
    });

  }

  handleGetDirections() {

    this.props.activeSite.getDirections();

    this.setState({
      gettingDirections: true
    });
    $(".getting-directions-loader").show();

  }

  handleRemoveDirections() {

    this.setState({
      gettingDirections: false
    });

    $(".getting-directions-loader").hide();


  }

  //
  // TODO: Add better transitions for showing/hiding the details view
  //

  //
  // TODO: Add some better animations for this reveal stuffs
  //
  handleShowVideo(evt) {
    var popup = new Foundation.Reveal($(this.refs.videoModal));
    popup.open();
  }

  handleCloseVideo(evt) {
    console.log("CLOSE THE VIDEO")
    var popup = new Foundation.Reveal($(this.refs.videoModal));
    popup.close();
    $('.reveal-overlay').remove();
  }

  /**
   * Getting our department links
   * @return {[type]} [description]
   */

  getContactLinks() {

    var contactLinks = []
    var siteType = this.props.activeSite.get("SITE_TYPE")

    if (siteType === "Park") {
      contactLinks.push((
        <a href="https://www.broomfield.org/317/Parks" target="_blank">
          <span className="ss-icon ss-pika ss-chat">&nbsp;</span>Contact
        </a>
      ))
    } else {
      contactLinks.push((
        <a href="https://www.broomfield.org/1061/Contact-Open-Space" target="_blank">
          <span className="ss-icon ss-pika ss-chat">&nbsp;</span>Contact
        </a>
      ))
    }

    return contactLinks;
  }

  /**
   * Getting our video links
   * @return {[type]} [description]
   */
  getVideoLinks() {

    var videoLinks = [];
    var videos = this.props.stores.videosCollection.getByGisId(this.props.activeSite.get("GIS_ID"));

    if (videos.length > 0) {

      videoLinks.push((
        <a key={videos[0].get("id")} onClick={this.handleShowVideo} data-open="video-modal">
          <span className="ss-icon ss-pika ss-facetime">&nbsp;</span> Watch Video
        </a>
      ));

    }
    return videoLinks;

  }

  /**
   * Getting our gallery photos
   * @return {[type]} [description]
   */
  getGalleryPhotos() {

    var photosContent = [];
    var photos = this.props.stores.photosCollection.getByGisId(this.props.activeSite.get("GIS_ID"));

    _.each(photos, function (photo) {
      var src = photo.get("SOURCE_URL");
      photosContent.push({
        original: src,
        thumbnail: src
      })
    });

    return photosContent;

  }

  /**
   * Getting caption content
   * @return {[type]} [description]
   */
  getGalleryCaptions() {

    var captionContent = [];
    var photos = this.props.stores.photosCollection.getByGisId(this.props.activeSite.get("GIS_ID"));

    _.each(photos, function (photo) {

      if (photo.get("CAPTION")) {
        captionContent.push((
          <p>{photo.get("CAPTION")}</p>
        ));
      }

    });

    if (captionContent.length) {
      captionContent = (
        <div className="gallery-captions" ref="captions">
          {captionContent}
        </div>
      );
    }

    return captionContent;

  }

  /**
   * Getting our nearby content
   * @return {[type]} [description]
   */
  getNearbyContent() {

    var self = this;

    var nearbys = [];
    var gisId = this.props.activeSite.get("GIS_ID");

    var nearbyRelationships = this.props.stores.nearbysCollection.getByGisId(gisId);
    var nearbyGisIds = [];

    _.each(nearbyRelationships, function (nearbyRelationship) {
      nearbyGisIds.push(nearbyRelationship.get("F_NEAR_GIS_ID"));
    });

    var nearbySites = this.props.stores.sitesCollection.getSitesByIds(nearbyGisIds);

    var handleShowNearby = function (nearbySiteModel) {

      self.props.mainStateDispatcher({
        action: "setActiveSite",
        showPopup: true,
        showDetails: false,
        siteModel: nearbySiteModel
      });
    }

    if (nearbySites.length) {

      nearbySites.each(function (nearbySite) {
        var permalink = nearbySite.getPermalink();
        var siteName = nearbySite.get("PLACE_NAME");
        nearbys.push((
          <h3 key={nearbySite.get("id")} className="uppercase feature"><a onClick={handleShowNearby.bind(this, nearbySite)}>{siteName}</a></h3>
        ));
      });

      return (
        <div className="info-block">
          <h2 className="light">Nearby</h2>
          <ul>
            {nearbys}
          </ul>
        </div>
      );

    }
    return nearbys;

  }

  /**
   * Feature links
   */
  getFeaturesContent() {

    var self = this;
    var featuresContent = [];
    var gisId = this.props.activeSite.get("GIS_ID");
    var features = this.props.stores.featuresCollection.getByGisId(gisId);

    var handleFeatureClick = function (featureId) {
      self.props.mainStateDispatcher({
        action: "showFeaturePopup",
        featureId: featureId
      });
    };

    if (features.length) {

      _.each(features, function (feature) {
        featuresContent.push((
          <h3 key={feature.get("id")} className="uppercase feature">
            <a onClick={handleFeatureClick.bind(this, feature.get("id"))} >{feature.get("SPECIFIC_NAME")}</a>
          </h3>
        ));
      });

      return (
        <div className="info-block">
          <h2 className="light light-green">Key Features</h2>
          {featuresContent}
        </div>
      );

    } else {

      return "";

    }
  }

  /**
   * Returning the modal popup
   * @return {[type]} [description]
   */
  getVideosModal() {
    var videos = this.props.stores.videosCollection.getByGisId(this.props.activeSite.get("GIS_ID"));
    if (videos.length) {
      var video = videos[0];

      return (
        <div ref="videoModal" id="video-modal" className="reveal" data-reveal>
          <div className="responsive-embed">
            <iframe width="560" height="315" src={video.get("SOURCE_URL")} frameBorder="0" allowFullScreen></iframe>
          </div>
        </div>
      );

    } else {
      return "";
    }

  }

  render() {

    if (!this.props.showDetails) {
      return (
        <div></div>
      );
    }

    var siteType = this.props.activeSite.get("SITE_TYPE").toLowerCase().replace(" ", "-");
    var name = this.props.activeSite.get("PLACE_NAME");
    var address = this.props.activeSite.get("ADDRESS");
    var city = "Broomfield";
    var state = "CO";
    var overview = this.props.activeSite.get("DESCRIPTION");
    // var directions = this.props.activeSite.get("GENERAL_DIRECTIONS");
    var amenities = this.props.activeSite.get("AMENITIES");

    var contactLinks = this.getContactLinks();
    var videoLinks = this.getVideoLinks();

    var galleryPhotos = this.getGalleryPhotos();
    var galleryCaptions = this.getGalleryCaptions();
    var nearbyContent = this.getNearbyContent();
    var featuresContent = this.getFeaturesContent();

    var videosModal = this.getVideosModal();

    var componentClass = "";
    componentClass += siteType;
    componentClass += " ";
    componentClass += this.props.showDetails === true ? "is-active" : "";
    componentClass += this.state.gettingDirections ? " getting-directions" : '';

    return (

      <div data-comp-details className={componentClass}>

        <div className="overlay white">

          <a className="close-detail" onClick={this.handleOnClose}>
            <span className="ss-icon ss-pika ss-delete"></span>
          </a>

          <header className="row">

            <div className="columns small-7">
              <h1 className="site-name">{name}</h1>
              <p className="site-address light">{address}<br />
                {city}, {state}</p>
            </div>

            <div className="columns small-5 details-navigation">

              {videoLinks}

              <a className="map-it" onClick={this.handleShowMap}>
                <span className="ss-icon ss-pika ss-pin"></span> Show On Map
                </a>

              <a className="get-directions" onClick={this.handleGetDirections}>
                <span className="ss-icon ss-pika ss-globe"></span> Get Directions <img src="/assets/img/loader-white.svg" className="getting-directions-loader" />
              </a>

              {contactLinks}

            </div>

          </header>

          <ImageGallery
            items={galleryPhotos}
            slideInterval={2000}
            onImageLoad={this.handleImageLoad}
            showThumbnails={true}
            showFullscreenButton={true} />

          <div className="row">
            <div className="columns small-12 medium-6">

              <div className="info-block">
                <h2 className="light">Overview</h2>
                <p>
                  {overview}
                </p>
              </div>
              <div className="info-block">
                <h2 className="light">Amenities</h2>
                <p>
                  {amenities}
                </p>
              </div>

              { }

            </div>

            <div className="columns small-12 medium-6">
              <div className="info-block">
                {nearbyContent}
                {featuresContent}
              </div>

            </div>

          </div>

          {videosModal}

        </div>

      </div>

    );

  }

};

export default DetailsComponent;
