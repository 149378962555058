import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

class SplashComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: true,

    };

    this.close = this.close.bind(this)
  }

  close() {
    this.setState({ modal: false });
  }

  render() {

    return (
      <Modal className="splash"
        isOpen={this.state.modal}
        fade={this.fade} >
        <ModalHeader>Broomfield Parks and Open Spaces</ModalHeader>
        <ModalBody>
          <div className="row">
            <p>Explore Broomfield’s Open Spaces, Parks, and Trails with our new interactive web map! Discover site amenities,
            trail connections, videos and more in this desktop and mobile-friendly application. Search for key open space sites, trails,
            and features like fishing spots, public art, and picnic pavilions. Or explore areas near you using the my location feature.
            More open spaces, parks, and trails are being added all the time! Have suggestions? Fill out the feedback form to help us
              continue improving this map by clicking on the <span className="btn-icon ss-icon ss-pika ss-help"></span> icon at the top right of the screen on the map.</p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.close} className="thanks-button">Close</Button>
          <div className="row">

            <strong><span>For direct assistance please contact </span><a target="_blank" href="https://www.broomfield.org/1061/Contact-Open-Space">Open Space and Trails</a></strong>
          </div>
        </ModalFooter>

      </Modal>

    );
  }
}

export default SplashComponent

