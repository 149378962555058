var L = require('leaflet');
L.esri = require('esri-leaflet');
var _ = require("underscore");
var Backbone = require('backbone');
var $ = require('jquery');

/**
 * Holds the sites that we show in the Sidebar module
 */

var SiteModel = Backbone.Model.extend({

  /**
   * Adjusting our site data, so properties are accessible
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  parse: function(data){

    var parsedData = data;

    _.each(data.properties, function(value, key){
      parsedData[key] = value;
    });

    parsedData.slug = data.properties.PLACE_NAME.replace(/[^A-Za-z0-9-]+/g, '-').toLowerCase();

    return parsedData;

  },

  /**
   * Getting he permalink for a site
   * @return {[type]} [description]
   */
  getPermalink: function(){

    return "/" + this.getFormattedSiteType()  + "/" + this.get("slug");

  },

  /**
   * Retruning the site type -- eg: openspaces, parks, trails
   */
  getFormattedSiteType: function(){

    return this.get("SITE_TYPE").toLowerCase().replace(" ", "") + "s";

  },

  getDirections: function(){

    var self = this;

    if(navigator.geolocation){

      navigator.geolocation.getCurrentPosition(function(position){

        var coords = {
          startingLatitude: self.get("geometry")["coordinates"][1],
          startingLongitude: self.get("geometry")["coordinates"][0],
          endingLatitude: position.coords.latitude,
          endingLongitude: position.coords.longitude
        }

        var link = self.buildDirectionsLink(coords);

        window.open(link,'_blank');

      });

    }else{

      alert("Geolocation not supported by this browser.");

    }

  },

  /**
   * Building a google maps link to give us directions based on the users location
   * @param  {object} startLatLng starting lat lng coords
   * @param  {object} endLatLng   ending lat lng coords
   * @return {string}             link
   */
  buildDirectionsLink: function(coords){
    var link = "https://maps.google.com/maps?";
    link += "f=d&";
    link += "saddr="+coords.startingLatitude+","+coords.startingLongitude+"&";
    link += "daddr="+coords.endingLatitude+","+coords.endingLongitude;
    return link;
  }

});

var SitesCollection = Backbone.Collection.extend({

  comparator: "PLACE_NAME",

  model: SiteModel,

  initialize: function(){

  },

  fetchSites: function(){

    var self = this;

    //
    // Init our Sites
    //
    var sitesQuery = L.esri.query({
      url: window.ApiEndpoint + "FeatureServer/2"
    });

    sitesQuery.where("1=1");

    sitesQuery.run(function(error, featureCollection, response){
      self.featureCollection = featureCollection;
      if (featureCollection)
        self.reset(featureCollection.features, {parse: true});
    });

  },

  /**
   * Returning a collection of sites, based on their Site Type (park, openspace, trail)
   * @param  {[type]} type [description]
   * @return {[type]}      [description]
   */
  getSitesByType: function(type){

    var siteType;

    var filtered = this.filter(function(result) {
      siteType = result.get("SITE_TYPE").toLowerCase().replace(" ", "");
      return  siteType === type;
    });

    return new SitesCollection(filtered);

  },

  /**
   * Returning a collection of sites, based on a passed array of gis ids
   * @return {[type]} [description]
   */
  getSitesByIds: function(ids){

    var siteType;

    var filtered = this.filter(function(result) {
      var gisId = result.get("GIS_ID");
      return  _.indexOf(ids, gisId) > -1;
    });

    return new SitesCollection(filtered);

  },


  /**
   * Returning a collection based on the search term
   * @param  {string} searchTerm the string we are searching
   * @return {collection}            a collection of result models
   */
  searchBy: function(searchTerm){

    var filtered = this.filter(function(result){

      var hasTerm = false;
      var amenities = result.get("AMENITIES").split(",");

      _.each(amenities, function(item, i){

        if(item.toLowerCase().trim().indexOf(searchTerm.toLowerCase()) > -1){
          hasTerm = true;
        }

      });

      var name = result.get("PLACE_NAME").toLowerCase();
      var term = searchTerm.toLowerCase();

      if(name.indexOf(term) > -1){
        hasTerm = true;
      }

      return hasTerm;

    });

    return new SitesCollection(filtered);

  },


  /**
   * Returns an array of objects formatted to be used in the typeahead library
   * used in the SidebarResultsHeaderSearchLayout class
   * @return {array} an array of objects, each of which using this format, { val: "value" }
   */
  getSearchableAmenitiesArray: function(){

    var amenities = [];

    $.each(this.pluck("AMENITIES"), function(i, modelAmenities){

      if(modelAmenities === undefined || modelAmenities === null){
        return;
      }

      modelAmenities = modelAmenities.split(",");
      _.each(modelAmenities, function(element, index, list){
        modelAmenities[index] = element.trim();
      });

      amenities = $.merge(amenities, modelAmenities);

    });

    return _.map(_.uniq(amenities), function(item){
      return {val: item};
    });

  },

  /**
   * Returns an array of objects formatted to be used in the typeahead library
   * used in the SidebarResultsHeaderSearchLayout class
   * @return {array} an array of objects, each of which using this format, { val: "value" }
   */
  getSearchableNamesArray: function(){

    var names = [];

    $.each(this.pluck("PLACE_NAME"), function(i, modelName){

      names.push({val: modelName});

    });

    return names;

  },

  matchFeature: function(featureId){



  }

});

module.exports = SitesCollection;
