import MapComponent from "./MapComponent";
import DetailsComponent from "./DetailsComponent";
var React = require('react');

class StageComponent extends React.Component {

  /**
   * Our filtered sites
   * @return {[type]} [description]
   */
  getFilteredSitesCollection() {

    var sitesCollection;

    if (this.props.view === "all") {

      sitesCollection = this.props.stores.sitesCollection;

    } else {

      sitesCollection = this.props.stores.sitesCollection.getSitesByType(this.props.view);

    }

    if (this.props.searchTerm !== false) {
      sitesCollection = sitesCollection.searchBy(this.props.searchTerm);
    }


    //
    // Creating a unique hash of the models in this collection, based on their containing ids,
    // so we can conditionally update our MapComponent, based on the unique hash. If the hash is different (ie: different sites)
    // update the map
    //
    var collectionHash = "";

    sitesCollection.each(function (model) {
      collectionHash += model.get("id");
    });

    sitesCollection.uniqueHash = collectionHash;

    return sitesCollection;

  }

  render() {

    var filteredSitesCollection = this.getFilteredSitesCollection();

    return (
      <div className={"stage columns small-12 medium-8 bg-light-green " + (this.props.showDetails ? 'showing-details' : '')}>
        <MapComponent
          mainStateDispatcher={this.props.mainStateDispatcher}
          view={this.props.view}
          stores={this.props.stores}
          searchTerm={this.props.searchTerm}
          filteredSitesCollection={filteredSitesCollection}
          activeSite={this.props.activeSite}
          showDetails={this.props.showDetails}
          showPopup={this.props.showPopup}
          featureId={this.props.featureId}
          areSearching={this.props.areSearching}
        />

        <DetailsComponent
          mainStateDispatcher={this.props.mainStateDispatcher}
          view={this.props.view}
          stores={this.props.stores}
          searchTerm={this.props.searchTerm}
          filteredSitesCollection={filteredSitesCollection}
          activeSite={this.props.activeSite}
          showDetails={this.props.showDetails}
        />
      </div>
    );

  }

};

export default StageComponent;
