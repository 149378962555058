import ModalComponent from '../stage/ModalComponent';
var React = require('react');
var Backbone = require("backbone");

class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <header className="root row bg-dark-green">
        <div className="large-6 columns">
          <div className="site-title">
            <a title="CCOB" href="https://www.broomfield.org/" style={{ marginRight: 1 + 'em' }}>
              <img src="/assets/img/ccob-logo-white.png" width="30"></img>
            </a>
            <a href="/" onClick={this.handleNavigationClick.bind(this, "all")}>PARKS & OPEN SPACES</a>
          </div>
        </div>
        <ModalComponent ref="ModalComponent" />
      </header>
    );
  }

  handleNavigationClick(view, evt) {

    evt.preventDefault();
    evt.stopPropagation();

    this.props.mainStateDispatcher({
      action: "setView",
      viewName: view
    });

  }

};

export default HeaderComponent;
