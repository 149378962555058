var React = require('react');

class SidebarIndexComponent extends React.Component {

  render() {
    return (
      <div data-comp-sidebar-intro>
        <div className="row">
          <div className="columns small-12 large-11 large-centered">
            <div id='buttons' className="buttons-container">

              <div id='button-park' className="small-4 columns">
                <a href="/parks" className="btn btn-round hover-dark-green" onClick={this.handleButtonClick.bind(this, "park")} title="Local Parks">
                  <span className="icon-park-site"></span>
                </a>
                <span className="btn-title">Local Parks</span>
              </div>

              <div id='button-openspaces' className="small-4 columns">
                <a href="/openspaces" className="btn btn-round hover-gold" onClick={this.handleButtonClick.bind(this, "openspace")} title="Open Space">
                  <span className="icon-openspace-site"></span>
                </a>
                <span className="btn-title">Open Spaces</span>
              </div>

              <div id='button-trails' className="small-4 columns">
                <a href="/trails" className="btn btn-round hover-brown" onClick={this.handleButtonClick.bind(this, "trail")} title="Trail System">
                  <span className="icon-trail-site"></span>
                </a>
                <span className="btn-title">Hiking Trails</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    );

  }

  handleButtonClick(viewName, event) {

    event.preventDefault();

    this.props.mainStateDispatcher({
      action: "setView",
      viewName: viewName
    });

  }

};

export default SidebarIndexComponent;
