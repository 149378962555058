import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import axios from 'axios';

class ModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      nestedModal: false,
      closeAll: false,
      name: '',
      email: '',
      feedback: '',
      honey: '918272635437',

    };

    this.toggle = this.toggle.bind(this);
    this.toggleNested = this.toggleNested.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeFeedback = this.handleChangeFeedback.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggleNested() {
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: false
    });
  }

  toggleAll() {
    this.setState({
      nestedModal: !this.state.nestedModal,
      modal: !this.state.nestedModal,
      closeAll: true
    });
  }

  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }
  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }
  handleChangeFeedback(event) {
    this.setState({ feedback: event.target.value });
  }

  handleSubmit(event) {

    this.setState({
      nestedModal: !this.state.nestedModal
    });
    // stop browser's default behaviour of reloading on form submit
    event.preventDefault();
    axios
      .post('/feedback', {
        name: this.state.name,
        email: this.state.email,
        comments: this.state.feedback,
        honey: this.state.honey
      })
      .then((response) => {
        console.log('from handle submit', response);
        this.setState({
          name: "",
          email: "",
          comments: ""
        });
      });
  }

  render() {

    return (
      <div id="feedback" className="feedback">
        <Button className="feedback-button green btn-icon ss-icon ss-pika ss-help" onClick={this.toggle}></Button>
        <Modal className="modal"
          ref="ModalComponent"
          isOpen={this.state.modal}
          toggle={this.toggle}
          fade={this.fade} >
          <form onSubmit={this.handleSubmit}>
            <ModalHeader>Send Us Your Feedback</ModalHeader>
            <ModalBody>
              <Modal className="modal" isOpen={this.state.nestedModal} toggle={this.toggleNested} onClosed={this.state.closeAll ? this.toggle : undefined}>
                <ModalBody className="nested-modal-content">Thank you for your response</ModalBody>
                <ModalFooter>
                  <Button className="close-button" onClick={this.toggleAll}>Ok</Button>
                </ModalFooter>
              </Modal>
              <div className="row">
                <div className="form-group col-md-4">
                  <input type="text" required={true} id='feedback-name' placeholder='Name' value={this.state.name} onChange={this.handleChangeName} className="form-control" />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-4">
                  <input type="text" required={true} id='feedback-email' placeholder='Email' value={this.state.email} onChange={this.handleChangeEmail} className="form-control" />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-4">
                  <textarea id='feedback-comments' required={true} placeholder='Feedback' value={this.feedback} onChange={this.handleChangeFeedback} className="form-control" />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button input type="submit" onClick={this.toggleNested} value="Submit" color="primary" data-loading-text="<i class='fa fa-spinner'></i> Submit" className="submit-button">Submit</Button>
              <Button onClick={this.toggle} className="cancel-button">Cancel</Button>
              <div className="row">
                <a href="mailto:gis@broomfield.org">Email: gis@broomfield.org</a>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

export default ModalComponent

