import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
var React = require('react');
var $ = require('jquery');
var ReactDOM = require('react-dom');

class SearchInputComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.initTypeahead = this.initTypeahead.bind(this);
    this.getSitesCollection = this.getSitesCollection.bind(this);
  }

  componentWillReceiveProps() {
    var self = this;
    this.initTypeahead();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.view !== this.props.view) {
      this.initTypeahead();
    }
  }

  initTypeahead() {
    var names = this.getSitesCollection().getSearchableNamesArray();
    var amenities = this.getSitesCollection().getSearchableAmenitiesArray();
    var namesAndAmenities = names.concat(amenities);
    var uniqueValues = [];

    //make sure there is only one of each search term
    $.each(namesAndAmenities, function (i, el) {
      if ($.inArray(el, uniqueValues) === -1) uniqueValues.push(el);
    });

    var searchTerm = this.props.searchTerm === false ? "" : this.props.searchTerm;

    ReactDOM.render(
      <Typeahead
        id="pagination-example"
        labelKey='val'
        placeholder="Search Names and Amenities..."
        defaultInputValue={searchTerm}
        options={uniqueValues}
        onChange={(selected) => {
          if (selected && selected.length > 0) {
            this.handleInputChange(selected[0].val)
            this.handleSubmit()
          }
        }}
        onInputChange={(text) => {
          this.handleInputChange(text)
        }}
        onKeyDown={(event) => {
          //checks for the enter key
          if (event.keyCode === 13) {
            this.handleSubmit()
          }
        }}
      />,
      document.getElementById('searchInput')
    );

  }

  /**
   * Returning a collection of our sites, if we have a prop set or not
   */
  getSitesCollection() {

    var sitesCollection;

    if (this.props.view === "all") {
      sitesCollection = this.props.stores.sitesCollection;
    } else {
      sitesCollection = this.props.stores.sitesCollection.getSitesByType(this.props.view);
    }

    return sitesCollection;

  }

  render() {
    return (
      <div id='row-2' className="row">
        <div className="columns small-12 filter-input-container search">
          <div id="searchInput" className="search-inputDiv"></div>
        </div>
      </div >
    );

  }

  handleSubmit() {
    this.props.mainStateDispatcher({
      action: "setAreSearching",
      areSearching: true
    });

  }

  handleInputChange(value) {

    this.props.mainStateDispatcher({
      action: "setSearchTerm",
      searchTerm: value
    });

    if (value.length < 3) {

      this.props.mainStateDispatcher({
        action: "setAreSearching",
        areSearching: false
      });

    }

  }

};

export default SearchInputComponent;
