import SiteResultComponent from "./SiteResultComponent";
import SearchInputComponent from "./SearchInputComponent";
import DropdownInputComponent from "./DropdownInputComponent";

var React = require('react');
var $ = require('jquery');

class SidebarResultsComponent extends React.Component {

    /**
     * Returning the correct input field for filtering our site results
     * @return {[type]} [description]
     */
    getFilterInputField() {

        if (this.props.view === "trails") {
            return (
                <DropdownInputComponent
                    stores={this.props.stores}
                    mainStateDispatcher={this.props.mainStateDispatcher}
                    areSearching={this.props.areSearching}
                />
            );
        } else {
            return (
                <SearchInputComponent
                    stores={this.props.stores}
                    view={this.props.view}
                    searchTerm={this.props.searchTerm}
                    mainStateDispatcher={this.props.mainStateDispatcher}
                    areSearching={this.props.areSearching}
                />
            );
        }

    }

    /**
     * Getting our collection of sites, based on the site type we're looking at
     * @return {[type]} [description]
     */
    getCollectionOfSites() {
        var sitesCollection;
        if (this.props.view === "all") {
            sitesCollection = this.props.stores.sitesCollection;
        } else {
            sitesCollection = this.props.stores.sitesCollection.getSitesByType(this.props.view);
        }
        return sitesCollection;
    }

    /**
     * Getting the results themselves
     * @return {[type]} [description]
     */
    getResults() {
        var self = this;
        var results = [];
        var collection = this.getCollectionOfSites();

        if (this.props.searchTerm !== false && this.props.areSearching) {
            collection = collection.searchBy(this.props.searchTerm);
        }

        collection.each(function (siteModel) {

            results.push((
                <SiteResultComponent
                    key={siteModel.get("id")}
                    site={siteModel}
                    mainStateDispatcher={self.props.mainStateDispatcher}
                    activeSite={self.props.activeSite}
                />
            ));
        });

        if (!results.length && this.props.searchTerm.length && this.props.areSearching) {

            results.push((
                <div>
                    <p className="no-results">
                        We&#39;re sorry, there were no results matching your search.
                    </p>
                    <p className="no-results">
                        Please revise and try again.
                    </p>
                </div>
            ));
        }
        return results;
    }

    render() {

        var results = this.getResults();
        var filterInputField = this.getFilterInputField();
        var content = null;
        if (this.props.width <= 767) {
            return (
                <div data-comp-sidebar-results>
                    <div className="sidebar-results-header">
                        <div id='row-1' className="row">
                            <div className="columns small-12 nix-pad-bot">
                                <ul className="btn-list">
                                    <li>
                                        <a id="parks-link" href="/parks" className={(this.props.view === "park" ? 'is-active ' : '') + "green btn btn-round ss-pika"} title="Local Parks" onClick={this.handleButtonClick.bind(this, "park")}>
                                            <span className="icon-park-site"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a id="openspaces-link" href="/openspaces" className={(this.props.view === "openspace" ? 'is-active ' : '') + "gold btn btn-round"} title="Open Space" onClick={this.handleButtonClick.bind(this, "openspace")}>
                                            <span className="icon-openspace-site"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a id="trails-link" href="/trails" className={(this.props.view === "trail" ? 'is-active ' : '') + "brown btn btn-round ss-pika"} title="Trail System" onClick={this.handleButtonClick.bind(this, "trail")}>
                                            <span className="icon-trail-site"></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {filterInputField}
                    </div>
                    <div id="clickMe" className="sidebar-results-title-open">
                        <label htmlFor="clickMe">Results - Click to Open/Close</label>
                    </div>
                    <div id="bottomPanel">
                        <div className="row sidebar-results-row" >

                            <div className="columns small-12 dark-green pt0">
                                {results}
                            </div>

                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div data-comp-sidebar-results>

                    <div className="sidebar-results-header">
                        <div id='row-1' className="row">
                            <div className="columns small-12 nix-pad-bot">
                                <ul className="btn-list">
                                    <li>
                                        <a id="parks-link" href="/parks" className={(this.props.view === "park" ? 'is-active ' : '') + "green btn btn-round ss-pika"} title="Local Parks" onClick={this.handleButtonClick.bind(this, "park")}>
                                            <span className="icon-park-site"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a id="openspaces-link" href="/openspaces" className={(this.props.view === "openspace" ? 'is-active ' : '') + "gold btn btn-round"} title="Open Space" onClick={this.handleButtonClick.bind(this, "openspace")}>
                                            <span className="icon-openspace-site"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a id="trails-link" href="/trails" className={(this.props.view === "trail" ? 'is-active ' : '') + "brown btn btn-round ss-pika"} title="Trail System" onClick={this.handleButtonClick.bind(this, "trail")}>
                                            <span className="icon-trail-site"></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {filterInputField}
                    </div>

                    <div className="row sidebar-results-row">

                        <div className="columns small-12 dark-green pt0">
                            {results}
                        </div>

                    </div>
                </div>

            );
        }

    }
    componentDidMount() {
        console.log("component mounted");
        var pos = 0;
        if (this.props.width <= 767) {
            $("#clickMe").click(function () {
                console.log("click fired");
                $("#bottomPanel").slideToggle("fast",
                    function () {
                        console.log("toggled");
                        if (pos == 0) {
                            $("#clickMe").removeClass("sidebar-results-title-open");
                            $("#clickMe").addClass("sidebar-results-title-close");
                            pos = 1;
                        }
                        else {
                            $("#clickMe").removeClass("sidebar-results-title-close");
                            $("#clickMe").addClass("sidebar-results-title-open");
                            pos = 0;

                        }
                    });
            });
        }
    }
    handleButtonClick(viewName, event) {

        event.preventDefault();

        this.props.mainStateDispatcher({
            action: "setView",
            viewName: viewName
        });

    }

};

export default SidebarResultsComponent;
