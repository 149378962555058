import SidebarIndexComponent from "./SidebarIndexComponent";
import SidebarResultsComponent from "./SidebarResultsComponent";
var React = require('react');
var ReactCSSTransitionGroup = require('react-addons-css-transition-group');

class SidebarComponent extends React.Component {

  getContentComponent() {

    if (this.props.view == false) {
      return null;

    } else if (this.props.view === "all") {
      return (
        <SidebarIndexComponent
          key="0"
          mainStateDispatcher={this.props.mainStateDispatcher}
        />

      );

    } else {
      return (
        <SidebarResultsComponent
          key="1"
          mainStateDispatcher={this.props.mainStateDispatcher}
          stores={this.props.stores}
          view={this.props.view}
          searchTerm={this.props.searchTerm}
          areSearching={this.props.areSearching}
          activeSite={this.props.activeSite}
          width={this.props.width}
        />

      );

    }

  }

  render() {

    var contentComponent = this.getContentComponent();

    return (
      <ReactCSSTransitionGroup
        transitionName="sidebar"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnterTimeout={1600}
        transitionLeaveTimeout={501}
        component="div"
        className="columns small-12 medium-4 bg-dark-green white"
        data-comp-sidebar>

        {contentComponent}

      </ReactCSSTransitionGroup>
    );

  }

};

export default SidebarComponent;
